import { Flex, Text, Box } from "@chakra-ui/react";
import React from "react";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import { AppImage } from "@/components/AppImage";
import { LinkButton } from "@/components/LinkButton";
import { BaseContainer } from "@/components/BaseContainer";
import { Routes ,Assets } from "@/lib";

function HomeIntro() {
  return (
      <BaseContainer px={{ base: "24px", xl: "18px" }} pt="210px" pb="75px">

      <FadeInAnimation>
        <Flex>
           <Box>
             <Text
                 fontSize={{ base: "3xl", md: "4xl" }}
                 fontFamily={"heading"}
                 fontWeight={"500"}
                 color={"brand100"}
             >
               Identifying and Closing Gaps in African Healthcare
             </Text>
             <Text
                 maxW={{ base: "100%", lg: "80%" }}
                 fontSize={"lg"}
                 mt="7"
                 mb={"20px"}
             >
             
             </Text>
           </Box>
        </Flex>
      </FadeInAnimation>
      
      <FadeInAnimation>
        <Flex
          flexDirection={["column", null, "row"]}
          rowGap={"34px"}
          columnGap={"78px"}
          py={"65px"}
        >
          {services.map((item) => (
            <Box textAlign={{ base: "center", md: "start" }} key={item.title}>
              <AppImage
                src={item.image}
                h={"200px"}
                w={"full"}
                objectPosition={"50% 50%"}
                objectFit={"contain"}
         

              />
              <Text
                fontFamily={"subtitle"}
                fontWeight={"800"}
                mt={"32px"}
                mb={"22px"}
                color={"brand100"}
              >
                {item.title}
              </Text>
              <Text fontSize={"lg"} my={"20px"}>
                {item.desc}
              </Text>
            </Box>
          ))}
        </Flex>
      </FadeInAnimation>
    </BaseContainer>
  );
}

export default HomeIntro;

const services = [
  {
    image: "/images/inno.svg",

    title: "Innovation for Impact",
    desc: "Pioneering cutting-edge AI solutions tailored to address Africa's unique healthcare challenges.",
  },
  {
    image: "/images/health.svg",
    title: "Inclusivity in Healthcare",
    desc: "Ensuring that our models and algorithms are accessible, affordable, and appropriate for the diverse needs of all African communities.",
  },
  {
    image: "/images/collabo.svg",
    title: "Collaboration and Partnership",
    desc: "Fostering strong partnerships, understanding that the best solutions come from working together and sharing knowledge.",
  },
];
