export const Advisors = [
    {
      name: "Bill Melton",
      role: "Business Advisor",
      img: "/images/Billc.png",
      about:
      "Founder -  Verifone : Cybercash Founder : Melton Foundation Founder", 
      
    },
    {
      name: "Dr Chitta Baral",
      role: "Technology Advisor",
      img: "/images/chittac.png",
      about:
        "Professor at Arizona State University",
    },
  ];
  