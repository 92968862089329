export const Teams = [
  {
    name: "Lindiwe Mutungamiri",
    role: "Founder and CEO",
    //img: "/images/lindiwe.jpeg",
    img: "/images/lincrofin.png",
    about:
    "Lindiwe Mutungamiri is pursuing a Master of Science in Computer Science at Arizona State Univerisity with a specialization in Artificial Intelligence. Her passion is in AI and Medicine and she is currenly doing research in BioNLP.  In her freshman of college, Lindiwe had the LITH idea and co-founded a startup called LITH Africa with a lecturer at her University. She describes herself as a creative disruptor and an innovative entrepreneur on a mission to create new and effective systems in the world. Lindiwe graduated from Ashesi University in 2022 with a BSc in Computer Science where she won several awards including the Ubora Rising Star Award and the Ustun Volleyball MVP Award. She has worked as a Business Strategy Analyst Intern with Stanbic Investment Management Services. She also served as a Junior Board of Directors Member of the 30-years-old Melton Foundation. When she is not trying to find innovative ways to solve problems, she can be found playing Volleyball, studying philosophy, reading a good book, or travelling to new places. ", 
    
  },
  {
    name: "Hiring",
    role: "Chief Technology Officer",
    img: "/images/hiring.png",
    about:
      " We are hiring a CTO to join our team. If you are interested, please send an email to lmutunga@asu.edu",
  },
  {
    name: "Hiring",
    role: "Chief Medical Officer",
    img: "/images/hiring.png",
    about:
      "We are hiring a CMO to join our team. If you are interested, please send an email to lmutunga@asu.edu ",
  },
  {
    name: "Hiring",
    role: "Chief Operations Officer",
    img: "/images/hiring.png",
    about:
      "We are hiring a COO to join our team. If you are interested, please send an email to lmutunga@asu.edu ",
  },
  {
    name: "Hiring",
    role: "Full Stack Developer",
    img: "/images/hiring.png",
    about:
      "We are hiring a Full Stack Developer to join our team. If you are interested, please send an email to lmutunga@asu.edu ",
  },
  {
    name: "Hiring",
    role: "Business Development Manager",
    img: "/images/hiring.png",
    about:
      "We are hiring a Business Development Manager to join our team. If you are interested, please send an email to lmutubga@asu.edu ",
  },
];
