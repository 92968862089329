import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDkMJwn70Dkx7PwOG46ZX_0lfdnOTImafA",
    authDomain: "eightnineone.firebaseapp.com",
    projectId: "eightnineone",
    storageBucket: "eightnineone.appspot.com",
    messagingSenderId: "186403711042",
    appId: "1:186403711042:web:c898b05c6b55fcf398e5db"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get Firestore instance
const db = getFirestore(app);

// Export Firestore instance
export { db };
