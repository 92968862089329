import React from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { AppImage } from "@/components/AppImage";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import { LinkButton } from "@/components/LinkButton";
import { BaseContainer } from "@/components/BaseContainer";
import TextUnderline from "@/components/TextUnderline";

const Data = [
  {
    title: "Mission",
    desc: 
            `Our mission is to innovate in medical AI, focusing on diseases prevalent in 
                                        Africa, to support healthcare professionals with advanced tools, 
                                        and to establish state-of-the-art medical facilities, improving healthcare
                                         accessibility and outcomes across the continent.`,
  },
  {
    title: "Vision",
    desc: ` To be the leading force in transforming healthcare across Africa, 
                                        leveraging AI to provide precise, efficient, 
                                        and culturally-relevant medical solutions, ultimately 
                                        reducing disease burden and enhancing the quality of life.`,
  },
  {
    title: "Why Eighty-Nine is Needed",
    desc: ` Eighty-Nine addresses the critical gap in healthcare AI, bringing data representation and 
                                      technological innovation to the forefront in Africa. By focusing on
                                       African-specific health challenges and data, we bridge the divide in 
                                       global healthcare technology, ensuring more accurate, effective care for
                                        the continent. Our dedication lies in making advanced AI healthcare a reality
                                         for every African community, revolutionizing local healthcare systems and outcomes.`,
  },
];

function AppIntro() {
  return (
    <Box position={"relative"}>
      <Box
        position={"absolute"}
        top={0}
        w={{ base: "100%" }}
        h={"100%"}
        zIndex={5}
        right={0}
      >
        <AppImage
          objectPosition={"30% 30%"}
          src={"/images/aboutVision.jpeg"}
          w={"100%"}
          h={"100%"}
        />
      </Box>
      <BaseContainer>
        <Flex
          bg={"brand200"}
          color={"white"}
          minHeight={"600px"}
          w={{ base: "100%", lg: "72%", xl: "46%" }}
          h={"100%"}
          flexDirection={"column"}
          justifyContent={"center"}
          zIndex={"10"}
          position={"relative"}
        >
          <Box maxWidth={{ base: "100%", md: "600px" }} w={"100%"}>
            <VStack
              alignItems={"start"}
              spacing={"20px"}
              px={{ base: "24px", md: "100px" }}
              py={"70px"}
            >
              {Data.map((item, index) => {
                return (
                  <Box key={index}>
                    <FadeInAnimation>
                      <TextUnderline
                        fontFamily={"heading"}
                        fontSize={"25px"}
                  
                        fontWeight={"900"}
                      >
                        {item.title}
                      </TextUnderline>
                      <p className="text text_dark pt-5">{item.desc}</p>
                    </FadeInAnimation>
                  </Box>
                );
              })}
            </VStack>
          </Box>
        </Flex>
      </BaseContainer>
    </Box>
  );
}

export default AppIntro;
