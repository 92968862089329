import Banner from "./components/Banner";
import HomeIntro from "./components/IntroSection";
import AppIntro from "@/screens/HomePage/components/AppIntro";
import DownloadSection from "@/screens/HomePage/components/DownloadSection";
import {Box , Text} from "@chakra-ui/react";
import {Assets} from "@/lib";
import React from "react";
import {BaseContainer} from "@/components/BaseContainer";
import AppLayout from "@/layout/AppLayout";
export const HomePageScreen = () => {
  return (
    <AppLayout>
      <Banner />
      <HomeIntro />
      <AppIntro />
      <DownloadSection />
        {/* <BaseContainer mt={'100px'}>
            <Text fontSize={{ base: "3xl", md: "4xl" }} fontFamily={"heading"}
fontWeight={"500"}
color={"brand100"}textAlign={'center'} mb={'40px'}>Backed By</Text>
            <Box mx={'auto'} zIndex={'6'} as={'img'} src={Assets.fastCompany} h={'300px'} width={'300px'}/>
        </BaseContainer> */}

<BaseContainer mt={'100px'}>
  <Text 
    fontSize={{ base: "3xl", md: "4xl" }} 
    fontFamily={"heading"}
    fontWeight={"500"}
    color={"brand100"}
    textAlign={'center'} 
    mb={'40px'}>
    Our Journey: First Pitch
  </Text>
  <Text 
    fontSize={{ base: "1xl", md: "1xl" }} 
    fontFamily={"heading"}
    fontWeight={"500"}
    color={"brand100"}
    textAlign={'center'} 
    mb={'10px'}>
    Arizona State University Entrepreneurship Competitions: March 2024
  </Text>
  <Box 
    mx={'auto'} 
    zIndex={'6'} 
    display="flex" 
    justifyContent="center" 
    alignItems="center">
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/d2h2mlHgDjA?si=fshSAmSn5wXi1aN3" 
      title="YouTube video player" 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      referrerPolicy="strict-origin-when-cross-origin" 
      allowFullScreen>
    </iframe>
  </Box>
</BaseContainer>


    </AppLayout>
  );
};



