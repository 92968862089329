import React from "react";
import { Box, chakra, Flex, VStack, Stack, Text } from "@chakra-ui/react";
import { BaseContainer } from "@/components/BaseContainer";
import Logo from "@/components/Logo";
import { NAV_ITEMS } from "@/components/Header/navData";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { AppImage } from "@/components/AppImage";
import { LinkButton } from "@/components/LinkButton";
const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={"white"}
      color={"brand200"}
      square={"full"}
      w={"30px"}
      h={"30px"}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "brand200",
        color: "whiteAlpha.800",
      }}
    >
      {children}
    </chakra.button>
  );
};

function Footer() {
  return (
    <Box py={"105px"} mt={"100px"} bg={"brand100"} color={"white"} bottom={"0"}>
      <BaseContainer px={{ base: "24px", xl: "18px" }}>
        {/* <Logo /> */}
        <img
        src="/images/89 logo white0.png"
        width="150px"
        height="55px"
        style={{ objectFit: 'contain' }}
        alt="eighty-nine"
      />
        <Text mt={"34px"}>
        Building Artificial Intelligence Enabled Health Solutions, Tailored for Africa.
        </Text>
        <Box>
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent={"start"}
            alignItems={"start"}
            gap={"123px"}
            mt={"75px"}
          >
            <Box>
              <Text fontSize={{ base: "2xl", md: "2xl" }} 
    fontFamily={"heading"}
    fontWeight={"100"}
    color={"white100"}
    mb={'10px'}>Contact Us</Text>
              <Text my={"10px"}>
                <Box as={"span"} color={"#83858a"}>
                  Email:{" "}
                </Box>
                <br></br>
                <Box as={"span"}>lmutunga@asu.edu</Box>
              </Text>
              <Text mb={"18px"}>
                <Box as="span" color={"#83858a"}>
                  Phone:{" "}
                </Box>
                <br></br>
                <Box as={"span"}>+1 602 552 1394</Box>
              </Text>
              <Text>
                <Box as={"span"} color={"#83858a"}>
                  Address:{" "}
                </Box>
                <br></br>
                <Box as={"span"}>
                  Tempe Campus
                  <br />
                  Arizona
                  <br />
                  USA
                </Box>
              </Text>
              <br></br>
              <br></br>
              <Box>
              <Text fontSize={"md"}>Follow us</Text>
              <Stack direction={"row"} spacing={6} mt={"8px"} mb={"34px"}>
                <SocialButton
                  label={"Instagram"}
                  href={"#"}
                >
                  <FaInstagram />
                </SocialButton>

                <SocialButton
                  label={"Facebook"}
                  href={"#"}
                >
                  <FaFacebook />
                </SocialButton>
                <SocialButton label={"Twitter"} href={"#"}>
                  {/* <FaTwitter /> */}
                  𝕏
                </SocialButton>
              </Stack>
              
              {/* <Text fontSize={"4xl"}>Get The App</Text> */}
              {/* <Box as={"span"}>
                Start Your Journey to optimal wellness with Lith
              </Box> */}
              <Stack direction={"row"} spacing={8} mt={"18px"}>
                {/*<AppImage*/}
                {/*  src={"/images/AppStore.png"}*/}
                {/*  w={"100%"}*/}
                {/*  h={"50px"}*/}
                {/*  objectFit={"contain"}*/}
                {/*/>*/}
                {/* <a
                  download={"#"}
                  href={"#"}
                >
                  <AppImage
                    src={"/images/androidDownload.png"}
                    w={"100%"}
                    h={"50px"}
                    objectFit={"contain"}
                  />
                </a> */}
              </Stack>
              <Text 
    fontFamily={"heading"}
    fontWeight={"100"}
    color={"white100"}
    mb={'10px'}>All Content Copyright: eighty-nine © 2024</Text>
    
 
    
            </Box>
            </Box>
    

            <Box>
              <Text fontSize={{ base: "2xl", md: "2xl" }} 
    fontFamily={"heading"}
    fontWeight={"100"}
    color={"white100"}
    mb={'10px'}>Our Platform</Text>
              <VStack alignItems={"start"} mt={"18px"} spacing="18px">
                {NAV_ITEMS.map((item) => (
                  <LinkButton
                    fontSize={"15px"}
                    color={"#FFFFFF"}
                    label={item.label}
                    useLink={item.id === "signIn" ? false : true}
                    href={item.link}
                    key={"index"}
                    my={"15px"}
                  />
                ))}

              </VStack>
            </Box>
            
            <Box>
              {/* <Text fontSize={"md"}>Follow us</Text>
              <Stack direction={"row"} spacing={6} mt={"8px"} mb={"34px"}>
                <SocialButton
                  label={"Instagram"}
                  href={"#"}
                >
                  <FaInstagram />
                </SocialButton>

                <SocialButton
                  label={"Facebook"}
                  href={"#"}
                >
                  <FaFacebook />
                </SocialButton>
                <SocialButton label={"Twitter"} href={"#"}>
                  <FaTwitter />
                </SocialButton>
              </Stack> */}
              
              {/* <Text fontSize={"4xl"}>Get The App</Text> */}
              {/* <Box as={"span"}>
                Start Your Journey to optimal wellness with Lith
              </Box> */}
              <Stack direction={"row"} spacing={8} mt={"18px"}>
                {/*<AppImage*/}
                {/*  src={"/images/AppStore.png"}*/}
                {/*  w={"100%"}*/}
                {/*  h={"50px"}*/}
                {/*  objectFit={"contain"}*/}
                {/*/>*/}
                {/* <a
                  download={"#"}
                  href={"#"}
                >
                  <AppImage
                    src={"/images/androidDownload.png"}
                    w={"100%"}
                    h={"50px"}
                    objectFit={"contain"}
                  />
                </a> */}
              </Stack>
            </Box>
          </Flex>

          {/* <Box mt={"34px"}>
            <AppImage
              maxWidth={"170px"}
              src={"/images/p5.png"}
              w={"auto"}
              h={"50px"}
              objectFit={"contain"}
            />
          </Box> */}
        </Box>
      </BaseContainer>
    </Box>
  );
}

export default Footer;
