import React from "react";
import PageHeader from "@/components/PageHeader";
import AboutIntro from "@/screens/AboutScreen/components/AboutIntro";
import MissionVision from "@/screens/AboutScreen/components/MissionVision";
import OurTeam from "@/screens/AboutScreen/components/OurTeam"
// import WaitList from "@/screens/AboutScreen/components/WaitList";

import OurAdvisors from "@/screens/AboutScreen/components/ourAdvisors";

import AppLayout from "@/layout/AppLayout";

export function AboutScreen() {
  return (
    <AppLayout>
      <PageHeader
        objectPosition={"30% 30%"}
        // image={"/images/aboutHeader.png"}
        image={"/images/happy-black.jpeg"}
        // introHead={"About Us"}
        // introBody={
        //   "We are empowering indivof technology and its ability to be a transformative tool for change. \n"
        // }
      />

      <AboutIntro />
      <MissionVision />
      <OurTeam />
      <OurAdvisors/>
      {/* <WaitList/> */}

    </AppLayout>
  );
}
