import React from 'react';
import {BaseContainer} from "@/components/BaseContainer";
import {FadeInAnimation} from "@/components/FadeInAnimation";
import {Flex} from "@chakra-ui/react";
import SectionItem from './SectionItem'

const features = [
    {
        //image: '/images/img1.svg',
        image: '/images/dashboard.gif',
        title: 'Our Platform',
        desc: 'Harness the power of AI-driven radiology insights, forge global connections with other medical professionals, and elevate your expertise through personalized learning experiences. ',
       

    },
    {
        image: '/images/SmartScanCropped.gif',
        title: 'Enhance Diagnostic Accuracy with AI',
        desc: 'Simply upload patient scans along with their medical details, and let our AI provide comprehensive assistance in interpreting the scans. Streamline your diagnostic process and reduce errors.',
        reverse : true,
    },
    {
        image: '/images/LH-cropped.gif',
        title: 'Elevate Your Medical Expertise',
        desc: 'Access a vast repository of knowledge, from cutting-edge research to practical diagnostic techniques, tailored specifically for healthcare professionals in Africa. Transform how you learn and stay updated.',
    },
    {
        image: '/images/PeerExchange.gif',
        title: 'Global Medical Collaboration',
        desc: 'Connect and consult with other doctors, sharing insights and seeking advice on complex cases. Foster a community of learning, support, and collaborative problem-solving, enhancing healthcare outcomes across borders.',
        reverse : true,
    },
]


function FeatureSection() {
    return (
        <BaseContainer
            px={{ base: "50px", xl: "18px" }}>

            <FadeInAnimation>
                <Flex flexDirection={'column'}>
                    {features.map((item) => (
                        <SectionItem key={item.title} item={item}/>
                    ))}

                </Flex>
            </FadeInAnimation>
        </BaseContainer>
    );
}

export default FeatureSection;