import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import { BaseContainer } from "@/components/BaseContainer";

function Banner() {
  return (
    <Box position={"relative"} overflow="hidden">
      <Box position={"absolute"} inset={0} zIndex={5}>
  <div style={{
    width: "100%",  
    height: "100%", 
    backgroundColor: "#000", // Assuming a dark background; adjust as needed
    backgroundImage: "url('https://media.giphy.com/media/lbcLMX9B6sTsGjUmS3/giphy.gif')", // A placeholder; you'll need the direct GIF URL
    backgroundSize: "cover", // Cover the entire area of the div
    backgroundPosition: "center", // Center the background image
   
  }}>
   
  </div>
  
</Box>


      
      <BaseContainer
        zIndex={"10"}
        py={"150px"}
        position={"relative"}
        backgroundColor={{ base: "blackAlpha.500", md: "transparent" }}
      >
        <Box
          w={{ base: "100%", lg: "72%", xl: "56%" }}
          maxWidth={"700px"}
          px={{ base: "24px", xl: "18px" }}
        >
          <FadeInAnimation>
            <Text
              fontSize={{ base: "4xl", md: "5xl" }}
              fontWeight="500"
              fontFamily={"heading"}
              color={'white'}
            >
              <br></br>
              <br></br>
              <br></br>
              <h1>
                <Text as={"span"} color={"white100"}>
                  AI-enabled health solutions
                </Text>
              </h1>
              
              <h1>
                <Text as={"span"} color={"white100"}>
                  tailored for Africa.
                </Text>
              </h1>
            </Text>
          </FadeInAnimation>
        </Box>
      </BaseContainer>
    </Box>
  );
}

export default Banner;

