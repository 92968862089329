import React from 'react';
import {BaseContainer} from "@/components/BaseContainer";
import {FadeInAnimation} from "@/components/FadeInAnimation";
import {Flex} from "@chakra-ui/react";
import SectionItem from '@/screens/FeaturesScreen/components/SectionItem';

const waitlist = [
    {
        image: "/images/3.svg",
        title: "1. Exclusive Early Access to Innovative Healthcare Solutions",
        desc: "By joining our waitlist, you gain exclusive early access to our pioneering AI-driven healthcare tools. These solutions are specifically designed to meet the unique challenges of the African healthcare landscape. Be among the first to leverage advanced diagnostic and predictive capabilities, improving patient outcomes and setting new standards in medical care.",
      },
      {
        image: "/images/2.svg",
        title: "2. Priority Support and Personalized Onboarding",
        desc: "Waitlist members receive priority support and personalized onboarding to ensure a seamless integration of our AI solutions into your practice. Our dedicated team will provide comprehensive training and continuous assistance, helping you maximize the benefits of our technology from day one. Experience unparalleled support and become a leader in utilizing cutting-edge healthcare innovations.",
      },
      {
        image: "/images/1.svg",
        title: "3. Join a Community of Healthcare Visionaries",
        desc: "Joining our waitlist connects you with a network of forward-thinking healthcare professionals committed to revolutionizing medical care in Africa. Participate in exclusive events, webinars, and forums to share insights, discuss challenges, and collaborate on best practices. This vibrant community is your gateway to staying at the forefront of healthcare advancements and making a significant impact on the well-being of African communities.",
      },
]


function WaitlistComponent() {
    return (
        <BaseContainer
            px={{ base: "50px", xl: "15px" }}>

            <FadeInAnimation>
                <Flex flexDirection={'column'}>
                    {waitlist.map((item) => (
                        <SectionItem key={item.title} item={item}/>
                    ))}

                </Flex>
            </FadeInAnimation>
        </BaseContainer>
    );
}

export default WaitlistComponent;