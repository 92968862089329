import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { AppImage } from "@/components/AppImage";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import { LinkButton } from "@/components/LinkButton";
import { BaseContainer } from "@/components/BaseContainer";
import { Routes } from "@/lib";
import TextUnderline from "@/components/TextUnderline";

function AppIntro() {
  return (
    <Box position={"relative"} bg={"brand200"} mt={"45px"}>
      <Box
        position={"absolute"}
        top={0}
        w={{ base: "100%", lg: "43%" }}
        h={"100%"}
        zIndex={5}
        right={0}
      >
        <AppImage src={"/images/homeImg.png"} w={"100%"} h={"100%"} />
      </Box>
      <BaseContainer
        backgroundColor={{ base: "blackAlpha.900", xl: "transparent" }}
        zIndex={"10"}
        position={"relative"}
      >
        <Flex
          minHeight={"600px"}
          w={{ base: "100%", xl: "56%" }}
          h={"100%"}
          flexDirection={"column"}
          justifyContent={"center"}
          zIndex={"10"}
          position={"relative"}
          px={{ base: "24px", xl: "18px" }}
        >
          <Box maxWidth={"600px"} w={"100%"}>
            <FadeInAnimation>
              <Text
                fontSize={{ base: "3xl", md: "4xl" }}
                fontWeight="500"
                fontFamily={"heading"}
                color={"white"}
              >
                For{" "}
                <Text as={"span"} color={"brand100"}>
                  Healthcare Providers
                </Text>{" "}
              Dedicated to {" "}
                <Text as={"span"} color={"brand100"}>
                 Advancing Healthcare
                </Text>{" "}
                in Africa.
               
              </Text>
              <Text fontSize="lg" color={"white100"} py={"24px"}>
             
                Eighty-Nine is designed for people who seek to revolutionize healthcare 
                in Africa, providing medical professionals and institutions with 
                AI-driven diagnostic tools and insights specifically tailored to 
                meet the unique health needs and challenges of the African continent.
              </Text>
              {/* <LinkButton
                href={Routes.features}
                label={"Learn more"}
                color={"white"}
              /> */}
            </FadeInAnimation>
          </Box>
        </Flex>
      </BaseContainer>
    </Box>
  );
}

export default AppIntro;
