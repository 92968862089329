import React, { useState } from "react";
import { Box, Text, SimpleGrid, VStack } from "@chakra-ui/react";
import { AppImage } from "@/components/AppImage";
import { BaseContainer } from "@/components/BaseContainer";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Advisors } from "@/lib/advisorsData";

function OurAdvisors() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);

  const openModal = (teamMember) => {
    setSelectedTeamMember(teamMember);
    setIsOpen(true);
  };

  return (
    <BaseContainer my={"100px"}>
      <Text mb={"32px"} fontSize="4xl" fontFamily={"heading"} fontWeight={"500"} px={{ base: "24px", xl: "18px" }} color={"brand100"}>
        Our Advisors
      </Text>
      <SimpleGrid columns={[2, null, null, 3]} spacingX={[1, 10]} spacingY={10}>
        {Advisors.map((item, index) => (
          <VStack key={index} spacing={4} onClick={() => openModal(item)} _hover={{ cursor: "pointer" }}>
            <AppImage src={item.img} objectFit="cover" w="full" h="auto" />
            <Box textAlign="center" w="full">
              <Text fontSize={["16px", "23px"]} fontFamily={"heading"} fontWeight={"500"} color={"black"}>
                {item.name}
              </Text>
              <Text fontSize={["14px", "20px"]} fontFamily={"body"} fontWeight={"400"} color={"gray.600"}>
                {item.role}
              </Text>
            </Box>
          </VStack>
        ))}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedTeamMember?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text fontWeight={"bold"}>Role:</Text>
            <Text mb={4}>{selectedTeamMember?.role}</Text>
            <Text fontWeight={"bold"}>About:</Text>
            <Text>{selectedTeamMember?.about}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </BaseContainer>
  );
}

export default OurAdvisors;
