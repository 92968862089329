import React, { useState, useRef } from "react";
import { collection, addDoc } from 'firebase/firestore';
import { BaseContainer } from "@/components/BaseContainer";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import PageHeader from "@/components/PageHeader";
import { db } from "../../firebase";
import "../../app.css"
import { Text } from "@chakra-ui/react";
import AppLayout from "@/layout/AppLayout";
import emailjs from '@emailjs/browser';
import WaitlistComponent from "./components/waitlist_components";
import "../../styles/waitlist.css"

const WaitList = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [institution, setInstitution] = useState("");
  const [profession, setProfession] = useState("");
  const [contact, setContact] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [loader, setLoader] = useState(false);
  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      console.log("Attempting to add to Firestore");
      await addDoc(collection(db, "waitlist"), {
        name: name,
        email: email,
        country: country,
        contact: contact,
        additionalInfo: additionalInfo, 
        institution: institution,
        profession: profession

      });
      alert("You have been added to the waitlist");
  
      sendEmail();
    } catch (error) {
      alert(error.message);
      setLoader(false);
    }
  };

  const sendEmail = () => {
    console.log("sendEmail method called");
    emailjs
      .sendForm('service_woump08', 'template_u9ugfd8', form.current, {
        publicKey: 'WSA_GXlm1TV4tIBxH',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setLoader(false);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setLoader(false);
        }
      );
      
    setName("");
    setEmail("");
    setCountry("");
    setContact("");
    setAdditionalInfo("");
    setInstitution("");
    setProfession("");
  };

  return (
  
    <AppLayout>
      <PageHeader
        objectPosition={"30% 30%"}
        image={"/images/about33.jpeg"}
      />
      <BaseContainer px={{ base: "24px", xl: "18px" }} pt="100px" pb="75px">
      <FadeInAnimation>
        <Text fontSize="4xl" fontFamily={"heading"} fontWeight={"500"} color={"brand100"}>
          Why Join Our Waitlist?
        </Text>
        </FadeInAnimation>

      <WaitlistComponent />
    
  <FadeInAnimation>
    <form className="form" ref={form} onSubmit={handleSubmit}>
      <h1>
        <Text fontSize="4xl" fontFamily="heading" fontWeight="500" color="brand100">
          Join Our Waitlist
        </Text>
      </h1>

      <div className="form-row">
        <div className="form-group">
          <label>Name</label>
          <input
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>Contact</label>
          <input
            name="contact"
            placeholder="Contact"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Country</label>
          <input
            name="country"
            placeholder="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          />
        </div>
      </div>

      
      <div className="form-row">
        <div className="form-group">
          <label>Institution</label>
          <input
            name="institution"
            placeholder="Institution"
            value={institution}
            onChange={(e) => setInstitution(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Profession</label>
          <input
            name="profession"
            placeholder="Profession"
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
            required
          />
        </div>
      </div>

      <label htmlFor="additional-info">Additional Information</label>
      <textarea
        id="additional-info"
        name="additionalInfo"
        placeholder="Enter additional information"
        value={additionalInfo}
        onChange={(e) => setAdditionalInfo(e.target.value)}
      ></textarea>

      <button
        type="submit"
        style={{ background: loader ? '#ccc' : 'rgb(2, 2, 110)' }}
      >
        Submit
      </button>
    </form>
    </FadeInAnimation>
    </BaseContainer>
    </AppLayout>

  );
};

export default WaitList;
