import { Routes } from "@/lib";

export const NAV_ITEMS = [
  { label: "Home", link: "/" },
  { label: "About Us", link: Routes.AboutUs },
  { label: "WaitList", link: Routes.WaitList },
  // { label: "ContactUS", link: Routes.ContactUs },
  { label: "Our Products", link: Routes.features },
  // { label: "Impact", link: Routes.impact },
  { label: "Impact", id: "impact", link: "#" },
  { label: "Research", id: "research", link: "#" },
];
