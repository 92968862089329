import React from "react";
import PageHeader from "@/components/PageHeader";
import { BaseContainer } from "@/components/BaseContainer";
import { FadeInAnimation } from "@/components/FadeInAnimation";
import { Box, Text } from "@chakra-ui/react";
import FeatureSection from "@/screens/FeaturesScreen/components/FeatureSection";
import { Assets } from "@/lib";
import AppLayout from "@/layout/AppLayout";

export function FeaturesScreen(props) {
  return (
    <AppLayout>
       <PageHeader
        objectPosition={"30% 30%"}
        image={"/images/about33.jpeg"}
        // introHead={"About Us"}
        // introBody={
        //   "We are empowering indivof technology and its ability to be a transformative tool for change. \n"
        // }
      />
      <BaseContainer px={["18px", "16px", "2%"]} py={"75px"}>
        <FadeInAnimation>
          <Text fontSize="4xl" fontFamily={"heading"} fontWeight={"500"} color={"brand100"}>
            How Our Technology Works
          </Text>
          <Text fontSize={"md"} mt="7" mb={"20px"} >
          The Medical Foundation Model is our inaugural product pre-trained with
           data specific to the African continent. We are developing algorithms 
           that serve as the backbone for a suite of AI-driven tools designed 
           to enhance capabilities in disease diagnosis, disease prediction,
            and ongoing education. We are developing solutions that meet the unique
             healthcare needs and challenges faced by communities across Africa.
          </Text>
          {/* <Text fontSize={"md"} mt="7" mb={"20px"}>
            LITH is also using the social network concept to boost personal
            growth and to make it more centralized to solve mental health
            issues. Unlike any other mental health apps, LITH has support groups
            moderated by our A.I systems to provide maximum support for the
            student’s mental health.
          </Text> */}
        </FadeInAnimation>
      </BaseContainer>
      <FeatureSection />
    </AppLayout>
  );
}
