import React from "react";
import { BaseContainer } from "@/components/BaseContainer";
import BlogItem from "@/screens/BlogScreen/BlogItem";
import AppLayout from "@/layout/AppLayout";
import researchData from "@/lib/blogTemplates";
import PageHeader from "@/components/PageHeader";

export function BlogScreen(props) {
  return (
    <AppLayout>
        <PageHeader
        objectPosition={"40% 40%"}
        image={"/images/header22.jpeg"}
        // introHead={"About Us"}
        // introBody={
        //   "We are empowering indivof technology and its ability to be a transformative tool for change. \n"
        // }
      />
      <BlogItem item={researchData[0]} />
      <BaseContainer py={"45px"}>
        {researchData.map((item, index) => {
          if (index === 0) return null;
          return <BlogItem key={index} item={item} />;
        })}
      </BaseContainer>
    </AppLayout>
  );
}
