const reasearchData = [
  {
  //   title: `Building resilience and supporting student mental health`,
  //   id: 1,
  //   name: `Jeanette Galan`,
  //   image: "/images/bl1.png",
  //   date: "Sep 22, 2020 2 min read",
  //   desc: `The purpose of therapy is to help you become your own parent—journaling helps you do that.`,
  //   content: `The purpose of therapy is to help you become your own parent—journaling helps you do that. The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   <br/>
  //   <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    <br/>
  //    <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   `,
  // },
  // {
  //   title: `LITH's top tips for managing exam and assignment stress and anxiety`,
  //   id: 2,
  //   name: `Jeanette Galan`,
  //   image: "/images/bl1.png",
  //   date: "Sep 22, 2020 2 min read",
  //   desc: `Sleep anxiety is the feeling of worry, nervousness, or unease associated with normal anxiety, except it occurs right before or during, sleep...`,
  //   content: `The purpose of therapy is to help you become your own parent—journaling helps you do that. The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    <br/>
  //    <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   `,
  // },
  // {
  //   title: `Using Meditation and Mindfulness to Help your Mental Health`,
  //   name: `Jeanette Galan`,
  //   image: "/images/bl1.png",
  //   id: 3,
  //   date: "Sep 22, 2020 2 min read",
  //   desc: `The purpose of therapy is to help you become your own parent—journaling helps you do that.`,
  //   content: `The purpose of therapy is to help you become your own parent—journaling helps you do that. The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   <br/>
  //   <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    <br/>
  //    <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   `,
  // },
  // {
  //   title: `Rockstars vs Superstars — get to know your people`,
  //   name: `Jeanette Galan`,
  //   image: "/images/bl1.png",
  //   id: 4,
  //   date: "Sep 22, 2020 2 min read",
  //   content: `The purpose of therapy is to help you become your own parent—journaling helps you do that. The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   <br/>
  //   <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    <br/>
  //    <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   `,
  // },
  // {
  //   title: `Journaling and therapy have more in common than you think`,
  //   name: `Jeanette Galan`,
  //   image: "/images/bl1.png",
  //   id: 5,
  //   date: "Sep 22, 2020 2 min read",
  //   desc: `The purpose of therapy is to help you become your own parent—journaling helps you do that.`,
  //   content: `The purpose of therapy is to help you become your own parent—journaling helps you do that. The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   <br/>
  //   <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    <br/>
  //    <br/>
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   The purpose of therapy is to help you become your own parent—journaling helps you do that.
  //   `,
  // },
  // {
  


    title: `AI and Medicine Research`,
    name: `eighty-nine-researcher`,
    image: "/images/header2.jpeg",
    id: 6,
    date: "Sep 22, 2020 2 min read",
    desc: `The purpose of therapy is to help you become your own parent—journaling helps you do that.`,
    content: `The purpose of therapy is to help you become your own parent—journaling helps you do that. The purpose of therapy is to help you become your own parent—journaling helps you do that.
    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
    <br/>
    <br/>
    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
    The purpose of therapy is to help you become your own parent—journaling helps you do that.
     The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
    The purpose of therapy is to help you become your own parent—journaling helps you do that.
     <br/>
     <br/>
    The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
    The purpose of therapy is to help you become your own parent—journaling helps you do that.
     The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.The purpose of therapy is to help you become your own parent—journaling helps you do that.
    The purpose of therapy is to help you become your own parent—journaling helps you do that.
    `,
  },
];

export default reasearchData;
